<template>
    <div class="h-20 w-full flex flex-row justify-center items-center text-gray-500">
        <div class="container flex flex-row justify-end items-center p-2">
            <div class="m-4">
                <button @click="goToLogin" class="cursor-pointer focus:outline-none">Login</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Header",
    methods: {
        goToLogin() {
            this.$router.push({ name:'Login'});
        }
    }
}
</script>

<style scoped>

</style>