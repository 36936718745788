<template>
    <div class="w-screen h-screen flex flex-col items-center justify-center bg-module">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "Outside"
}
</script>