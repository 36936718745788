<script>
    import Request from './Request';

    export default {
        extends: Request,
        name: 'All',
        props: {
            action: {},
            query: {
                type: Object,
                default: () => ({})
            },
            sensitive: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                localData: []
            };
        },
        methods: {
            async request() {
                try {
                    this.loading = true;
                    this.$emit('loading:change', this.loading);

                    const model = await this.getModel();
                    this.localData = await model.all({
                        query: this.query,
                        ...this.config
                    });
                    //console.log(this.localData);

                    this.$emit('update:data', this.localData);
                    this.$emit('success', this.localData);
                } catch (error) {
                    this.error = error;
                    /*if (error === 401 || error === 403) {
                        await this.$router.push({name: 'Inside'})
                    }*/
                } finally {
                    this.loading = false;
                    this.$emit('loading:change', this.loading);
                }
            },
        }
    }
</script>

<style scoped>

</style>