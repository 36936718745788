<template>
    <Outside>
        <Header/>
        <div class="h-full w-full flex flex-col justify-center items-center p-4">
            <div class="flex flex-col justify-center items-center px-4 bg-bo-light-html rounded rounded-md">
                <div class="w-full p-4">
                    <label class="block text-center">
                        <p class="text-gray-700">Podemos enviarte un enlace para que restablezcas tu contraseña.</p>
                        <p class="text-gray-700">Indica aquí el correo relacionado con tu cuenta</p>
                        <input v-model="form.email" class="form-input mt-1 block w-full text-center" placeholder="E-mail" autofocus>
                    </label>
                </div>
                <div class="w-full p-4 text-center">
                    <button @click="sendEmail" class="button-cust-default w-40">Enviar</button>
                    <Request model="Auth" ref="sendResetLinkRef" :form="form" action="sendResetLink" @success="onSuccess" @error="onError"/>
                </div>
            </div>
        </div>
    </Outside>
</template>

<script>
import {Request} from "@/api/components";
import Outside from "@/pages/outside/shared/Outside";
import Header from "@/pages/outside/shared/Header";

export default {
    name: "SendResetLink",
    components: {
        Request, Outside, Header
    },
    data() {
        return {
            form:{
                email: null,
            },
        };
    },
    methods: {
        onError(errors) {
            this.toastErrors(errors)
        },
        async onSuccess(response) {
            this.$router.push({ name: 'Login' });
            this.$notify(
                { group: "generic", title: 'Info', text: response.success, type: 'info'},
                4000
            );
        },
        sendEmail() {
            this.$refs.sendResetLinkRef.request();
        },
    }
}
</script>

<style scoped>

</style>